import React from "react";
import Web3Utils from "web3-utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { navigate } from "gatsby";
import { withAuthentication } from "../../../hoc/withAuthentication";
import * as styles from "./index.module.css";
import Main from "../../../components/main";
import Button from "../../../components/button";
import Input from "../../../components/input";
import NotFoundPage from "../../../components/notFoundPage";
import ContentArea from "../../../components/contentArea";
import SignOverlay from "../../../components/signOverlay";
import TextArea from "../../../components/textArea";
import DropdownInput from "../../../components/dropdownInput";
import ChainBox from "../../../components/chainBox";
import SelectionBox from "../../../components/selectionBox";
import MediaStickyPreview from "../../../components/mediaStickyPreview";
import LinkWithIcon from "../../../components/linkWithIcon";
import Consts from "../../../config/consts";
import Common from "../../../config/common";
import SaveBar from "../../../components/saveBar";
import Provider from "../../../config/provider";
import Exchange from "../../../config/exchange";
import Util from "../../../config/util";
import Api from "../../../config/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrown, faBan, faCoins, faGavel, faTag, faArrowUp91, faArrowLeftLong, faLock, faTimes, faArrowDown, faChevronDown } from "@fortawesome/free-solid-svg-icons";

/*



*/
export default withAuthentication(
    {
        requiredAuthLevels: [Consts.AUTH_LEVELS.AUTHENTICATED],
        redirectPage: "/",
    },
    class MediaMList extends React.Component {
        constructor(props) {
            super(props);
            this.auctionStartDateRef = React.createRef();
            this.auctionEndDateRef = React.createRef();

            this.state = {
                initialising: true,
                incomplete: false,
                media: null,
                saving: false,
                requiresSigning: false,
                lazyMintSignature: null,
                mediaId: props.params.media_id,

                addresses: props.auth.user.addresses,

                listingTypes: [
                    {
                        name: "Fixed Price",
                        subtext: "Sell your media at a fixed price.",
                        icon: faTag,
                        id: "FIXED_PRICE_SALE",
                    },
                    {
                        name: "Auction",
                        subtext: "Sell your media through an auction; highest bid wins.",
                        icon: faGavel,
                        id: "AUCTION_SALE",
                    },
                ],
                listingType: "NOT_FOR_SALE",
                listingTypePreSelection: "NOT_FOR_SALE",
                fixedPrice: null,

                quantityOwnedMinted: 0,
                quantityOwnedUnminted: 0,
                ownerPublicAddress: null,
                mintState: "NOT_MINTED",
                listingQuantity: 1,

                listingQuantityError: "",

                auctionReserve: 0,
                auctionStartDateType: null,
                auctionStartDate: null,
                auctionEndDateType: null,
                auctionEndDate: null,
                auctionStartDateOptions: [
                    { id: "ASAP", text: "ASAP" },
                    { id: "SPECIFIC_DATE", text: "Specific Date" },
                ],
                auctionEndDateOptions: [
                    { id: "AFTER_1_DAY", text: "After 1 Day" },
                    { id: "AFTER_3_DAY", text: "After 3 Days" },
                    { id: "AFTER_5_DAY", text: "After 5 Days" },
                    { id: "AFTER_7_DAY", text: "After 7 Days" },
                    { id: "SPECIFIC_DATE", text: "Specific Date" },
                ],

                chainsFixedPrice: [],
                chainsAuction: [],
                fixedSelectedCoinSymbol: "ETH",
                fixedSelectedCoinObj: null,
                auctionSelectedCoinSymbol: "wETH",
                auctionSelectedCoinObj: null,

                royaltiesPercent: 0,
                supply: 1,
                properties: [{ key: "", value: "" }],
                hiddenContent: "",
                hiddenContentVisible: false,

                lazyMintTypedData: null,
                tokenAddress: null,
                isMobile: window.innerWidth <= 768
            };
        }
        resize() {
            let currentIsMobile = window.innerWidth <= 768;
            if (currentIsMobile !== this.state.isMobile) {
                this.setState({isMobile: currentIsMobile});
            }
        }

        componentWillUnmount() {
            window.removeEventListener("resize", this.resize.bind(this));
        }

        componentDidMount() {

            window.addEventListener("resize", this.resize.bind(this));
            Api.market({
                endpoint: "/content/media",
                method: "GET",
                data: {
                    mediaId: this.props.params.media_id,
                },
            })
                .then((media_res) => {
                    if (Common.objectPropertyExists(media_res, "data.created", false) === false && Common.objectPropertyExists(media_res, "data.owner.me", null) === null) {
                        navigate(`/m/${this.state.mediaId}`);
                    } else {
                        // Check that the media is in a position where listing can happen - minting will need
                        // to have been setup prior to listing - it doesn't need to have been minted - just setup
                        // in preparation for minting.
                        Promise.all([
                            Api.market({
                                endpoint: "/chain/currencies",
                                method: "GET",
                                data: {
                                    actions: "FIXED_PRICE_SALE",
                                },
                            }),
                            Api.market({
                                endpoint: "/chain/currencies",
                                method: "GET",
                                data: {
                                    actions: "AUCTION_RESERVE",
                                },
                            }),
                        ])
                            .then((res) => {
                                let fixed_price_chains = res[0].currencies.sort((a, b) => a.name.localeCompare(b.name));
                                let auction_chains = res[1].currencies.sort((a, b) => a.name.localeCompare(b.name));

                                // Grab only the listings that are ours
                                let user_listings = media_res.data.listings.filter((listing) => {
                                    return Common.isDisplayNameMine(this.props.auth.user, listing.owner.displayName);
                                });

                                // Look for our selected coin obj
                                let fixed_selected_coin_obj = null;
                                let auction_selected_coin_obj = null;
                                let selected_fixed_coin = Common.objectPropertyExists(user_listings[0], "fixedPriceCoin", "ETH");
                                let selected_auction_coin = Common.objectPropertyExists(user_listings[0], "auctionReserveCoin", null);
                                let default_auction_coin = "wETH";
                                let default_fixed_coin = "ETH";
                                for (let i = 0; i < fixed_price_chains.length; i++) {
                                    if (fixed_price_chains[i].symbol === (selected_fixed_coin || default_fixed_coin)) {
                                        fixed_selected_coin_obj = fixed_price_chains[i];
                                        break;
                                    }
                                }
                                for (let i = 0; i < auction_chains.length; i++) {
                                    if (auction_chains[i].symbol === (selected_auction_coin || default_auction_coin)) {
                                        auction_selected_coin_obj = auction_chains[i];
                                        break;
                                    }
                                }

                                // Grab out the start/end dates for any auction sale
                                let auction_start_date = Common.objectPropertyExists(user_listings[0], "auctionStartDate", null);
                                let auction_end_date = Common.objectPropertyExists(user_listings[0], "auctionEndDate", null);
                                console.log(media_res);

                                // Sort the token address out - setting this as "0" has caused all sorts of issues. But it also can't be null.
                                // If its not "0" use whatever is set, else use the marketplaceNftContractAddress from state
                                const tokenAddress = media_res.data.tokenAddress != "0" ? media_res.data.tokenAddress : this.props.currentChain.marketplaceNftContractAddress;
                                this.setState(
                                    {
                                        chainsFixedPrice: fixed_price_chains,
                                        chainsAuction: auction_chains,
                                        fixedSelectedCoinSymbol: selected_fixed_coin || default_fixed_coin,
                                        fixedSelectedCoinObj: fixed_selected_coin_obj,
                                        fixedPrice: Web3Utils.fromWei(Common.objectPropertyExists(user_listings[0], "fixedPrice", "0") || "0", "wei"),
                                        auctionSelectedCoinSymbol: selected_auction_coin || default_auction_coin,
                                        auctionSelectedCoinObj: auction_selected_coin_obj,
                                        auctionStartDateType: auction_start_date === null ? "ASAP" : "SPECIFIC_DATE",
                                        auctionStartDate: auction_start_date ? new Date(auction_start_date) : null,
                                        auctionEndDateType: auction_end_date === null ? "AFTER_1_DAY" : "SPECIFIC_DATE",
                                        auctionEndDate: auction_end_date ? new Date(auction_end_date) : null,
                                        initialising: false,
                                        media: media_res.data,
                                        auctionReserve: Web3Utils.fromWei(Common.objectPropertyExists(user_listings[0], "auctionReserve", "0") || "0", "wei"),
                                        listingType: Common.objectPropertyExists(user_listings[0], "state", "NOT_FOR_SALE"),
                                        listingTypePreSelection: Common.objectPropertyExists(user_listings[0], "state", "NOT_FOR_SALE"),
                                        listingQuantity: Common.objectPropertyExists(user_listings[0], "quantity", 1),
                                        quantityOwnedUnminted: Common.objectPropertyExists(media_res, "data.owner.me.quantity.unminted", 0),
                                        quantityOwnedMinted: Common.objectPropertyExists(media_res, "data.owner.me.quantity.minted", 0),
                                        ownerPublicAddress: Common.objectPropertyExists(media_res, "data.owner.me.publicAddress", null),
                                        mintState: Common.objectPropertyExists(media_res, "data.owner.me.mintState", "NOT_MINTED"),
                                        royaltiesPercent: Common.objectPropertyExists(media_res, "data.royaltiesPercent", 0),
                                        supply: Common.objectPropertyExists(media_res, "data.totalSupply", 0),
                                        totalMinted: Common.objectPropertyExists(media_res, "data.totalMinted", 0),
                                        properties: Common.objectPropertyExists(media_res, "data.attributes", [{ key: "", value: "" }]),
                                        hiddenContent: Common.objectPropertyExists(media_res, "data.hiddenContent", ""),
                                        tokenAddress
                                    },
                                    () => {
                                        // If our listingTypePreSelection is not currently NOT_FOR_SALE then the user can
                                        // only run the action of removing from sale - so lets pre-select that now
                                        if (this.state.listingTypePreSelection !== "NOT_FOR_SALE") {
                                            this.setState(
                                                {
                                                    listingType: "NOT_FOR_SALE",
                                                },
                                                () => {
                                                    this.validate();
                                                }
                                            );
                                        } else {
                                            this.validate();
                                        }
                                    }
                                );
                            })
                            .catch((e) => {
                                console.log("Caught exception", { error: e });
                                this.setState({
                                    initialising: false,
                                });
                            });
                    }
                })
                .catch((e) => {
                    this.setState({
                        initialising: false,
                    });
                });
        }

        listingTypeSelection = (type) => {
            this.setState(
                {
                    listingType: type,
                },
                this.validate
            );
        };
        onFixedPriceChanged = (text) => {
            this.setState(
                {
                    fixedPrice: text,
                },
                this.validate
            );
        };

        onListingQuantityChanged = (text) => {
            this.setState(
                {
                    listingQuantity: Number(text),
                },
                this.validate
            );
        };

        onAuctionReserveChanged = (text) => {
            this.setState(
                {
                    auctionReserve: text,
                },
                this.validate
            );
        };

        onPreSelectRoyalties0 = () => {
            this.onRoyaltyPercentChanged(0);
        };
        onPreSelectRoyalties10 = () => {
            this.onRoyaltyPercentChanged(10);
        };
        onPreSelectRoyalties20 = () => {
            this.onRoyaltyPercentChanged(20);
        };
        onPreSelectRoyalties30 = () => {
            this.onRoyaltyPercentChanged(30);
        };
        onPreSelectRoyalties40 = () => {
            this.onRoyaltyPercentChanged(40);
        };
        onPreSelectRoyalties50 = () => {
            this.onRoyaltyPercentChanged(50);
        };
        onRoyaltyPercentChanged = (text) => {
            if (isNaN(text)) {
                Util.notify.error("Invalid percent entered, please enter a value between 0 and");
            } else {
                this.setState({
                    royaltiesPercent: Number(text),
                });
            }
        };

        onSupplyChanged = (text) => {
            this.setState({
                supply: text,
            });
        };

        onPropertyKeyChange = (text, index) => {
            this.setState(
                {
                    properties: [
                        ...this.state.properties.map((p, i) => {
                            if (i === index) {
                                return {
                                    key: text,
                                    value: p.value,
                                };
                            }
                            return p;
                        }),
                    ],
                },
                () => {
                    // Check if we have an empty row - if not lets add one in
                    if (this.state.properties.filter((p) => p.key === "" && p.value === "").length <= 0) {
                        this.setState({
                            properties: [...this.state.properties, ...[{ key: "", value: "" }]],
                        });
                    }
                }
            );
        };
        onPropertyValueChange = (text, index) => {
            this.setState(
                {
                    properties: [
                        ...this.state.properties.map((p, i) => {
                            if (i === index) {
                                return {
                                    key: p.key,
                                    value: text,
                                };
                            }
                            return p;
                        }),
                    ],
                },
                () => {
                    // Check if we have an empty row - if not lets add one in
                    if (this.state.properties.filter((p) => p.key === "" && p.value === "").length <= 0) {
                        this.setState({
                            properties: [...this.state.properties, ...[{ key: "", value: "" }]],
                        });
                    }
                }
            );
        };
        onDeleteProperty = (index) => {
            if (this.state.properties.length <= 1) {
                this.setState({
                    properties: [{ key: "", value: "" }],
                });
            } else {
                this.setState({
                    properties: this.state.properties.filter((p, i) => {
                        return i !== index;
                    }),
                });
            }
        };

        onHiddenContentChanged = (text) => {
            this.setState({
                hiddenContent: text,
            });
        };

        hideSignOverlay = () => {
            this.setState({
                saving: false,
                requiresSigning: false,
            });
        };

        onRequestListing = () => {
            this.setState({
                saving: true,
                requiresSigning: true,
            });
        };
        /*
            OnSigned handles the following situations:
            + When we are unlisting
            + When are are listing an already minted/lazy minted media
            + Otherwise we ignore
        */
        onSigned = async (signature) => {
            let error = null;
            if (this.state.listingType === "NOT_FOR_SALE") {
                if (this.state.listingTypePreSelection === "FIXED_PRICE_SALE") {
                    error = await Exchange.StopFixedListing({
                        signature: signature,
                        mediaId: this.state.mediaId,
                    });
                } else if (this.state.listingTypePreSelection === "AUCTION_SALE") {
                    error = await Exchange.StopAuctionListing({
                        signature: signature,
                        mediaId: this.state.mediaId,
                    });
                }
            } else if (this.state.mintState === "MINTED" || this.state.mintState === "LAZY_MINTED") {
                // Run through our addresses to find the appropriate provider
                let provider = null;
                for (let i = 0; i < this.props.auth.user.addresses.length; i++) {
                    if (this.props.auth.user.addresses[i].publicAddress === this.state.ownerPublicAddress) {
                        provider = this.props.auth.user.addresses[i].provider;
                    }
                }

                if (this.state.listingType === "FIXED_PRICE_SALE") {
                    error = await Exchange.StartFixedListing({
                        providerId: provider,
                        from: this.state.ownerPublicAddress,
                        exchangeContractAddress: this.props.currentChain.exchangeContractAddress,
                        contractAddress: this.state.tokenAddress,
                        signature: signature,
                        mediaId: this.state.mediaId,
                        fixedPrice: this.state.fixedPrice,
                        fixedPriceSymbol: this.state.fixedSelectedCoinSymbol,
                        listingQuantity: Number(this.state.listingQuantity),
                    });
                } else {
                    // Make the call to start an auction sale
                    error = await Exchange.StartAuctionListing({
                        providerId: provider,
                        from: this.state.ownerPublicAddress,
                        exchangeContractAddress: this.props.currentChain.exchangeContractAddress,
                        contractAddress: this.state.tokenAddress,
                        signature: signature,
                        mediaId: this.state.mediaId,
                        listingQuantity: Number(this.state.listingQuantity),
                        auctionReserve: this.state.auctionReserve,
                        auctionReserveSymbol: this.state.auctionSelectedCoinSymbol,
                        auctionStartDateType: this.state.auctionStartDateType,
                        auctionStartDate: this.state.auctionStartDateType === "SPECIFIC_DATE" ? this.state.auctionStartDate.toISOString() : "0",
                        auctionEndDateType: this.state.auctionEndDateType,
                        auctionEndDate: this.state.auctionEndDateType === "SPECIFIC_DATE" ? this.state.auctionEndDate.toISOString() : "0",
                    });
                }
            } else {
                // If we land here then we need to wait for the onMinted call to be thrown
                // so we'll exit out of this function.
                this.setState({
                    lazyMintSignature: signature,
                });
                return;
            }

            if (!error) {
                setTimeout(() => {
                    navigate(`/m/${this.state.mediaId}`);
                }, 2000);
            } else {
                this.hideSignOverlay();
            }
        };

        onMinted = async (error) => {
            // Run through our addresses to find the appropriate provider
            let provider = null;
            for (let i = 0; i < this.props.auth.user.addresses.length; i++) {
                if (this.props.auth.user.addresses[i].publicAddress === this.state.ownerPublicAddress) {
                    provider = this.props.auth.user.addresses[i].provider;
                }
            }

            if (this.state.listingType === "FIXED_PRICE_SALE") {
                error = await Exchange.StartFixedListing({
                    providerId: provider,
                    from: this.state.ownerPublicAddress,
                    exchangeContractAddress: this.props.currentChain.exchangeContractAddress,
                    contractAddress: this.state.tokenAddress,
                    signature: this.state.lazyMintSignature,
                    mediaId: this.state.mediaId,
                    fixedPrice: this.state.fixedPrice,
                    fixedPriceSymbol: this.state.fixedSelectedCoinSymbol,
                    listingQuantity: Number(this.state.listingQuantity),
                });
            } else {
                // Make the call to start an auction sale
                error = await Exchange.StartAuctionListing({
                    providerId: provider,
                    from: this.state.ownerPublicAddress,
                    exchangeContractAddress: this.props.currentChain.exchangeContractAddress,
                    contractAddress: this.state.tokenAddress,
                    signature: this.state.lazyMintSignature,
                    mediaId: this.state.mediaId,
                    listingQuantity: Number(this.state.listingQuantity),
                    auctionReserve: this.state.auctionReserve,
                    auctionReserveSymbol: this.state.auctionSelectedCoinSymbol,
                    auctionStartDateType: this.state.auctionStartDateType,
                    auctionStartDate: this.state.auctionStartDateType === "SPECIFIC_DATE" ? this.state.auctionStartDate.toISOString() : "0",
                    auctionEndDateType: this.state.auctionEndDateType,
                    auctionEndDate: this.state.auctionEndDateType === "SPECIFIC_DATE" ? this.state.auctionEndDate.toISOString() : "0",
                });
            }

            if (!error) {
                setTimeout(() => {
                    navigate(`/m/${this.state.mediaId}`);
                }, 2000);
            } else {
                this.setState({
                    minting: false,
                    requiresSigning: false,
                });
            }
        };

        onFixedCoinChange = (text) => {
            this.setState(
                {
                    fixedSelectedCoinSymbol: text,
                    fixedSelectedCoinObj: this.state.chainsFixedPrice.filter((c) => {
                        return c.symbol === text;
                    })[0],
                },
                () => {
                    this.validate();
                }
            );
        };

        onAuctionReserveCoinChange = (text) => {
            this.setState({
                auctionSelectedCoinSymbol: text,
                auctionSelectedCoinObj: this.state.chainsAuction.filter((c) => {
                    return c.symbol === text;
                })[0],
            });
        };

        onAuctionStartDateChange = (value_id) => {
            if (value_id === "SPECIFIC_DATE") {
                this.setState(
                    {
                        auctionStartDate: this.state.auctionStartDateType !== value_id ? null : this.state.auctionStartDate,
                        auctionStartDateType: value_id,
                    },
                    () => {
                        this.auctionStartDateRef.current.setOpen(true);
                    }
                );
            } else {
                this.setState({
                    auctionStartDateType: value_id,
                    auctionStartDate: this.state.auctionStartDateOptions.filter((o) => {
                        return o.id === value_id;
                    })[0].text,
                });
            }
        };
        auctionStartDateChanged = (date) => {
            this.setState({
                auctionStartDate: date,
            });
        };

        onAuctionEndDateChange = (value_id) => {
            if (value_id === "SPECIFIC_DATE") {
                this.setState(
                    {
                        auctionEndDateType: value_id,
                        auctionEndDate: this.state.auctionEndDateType !== value_id ? null : this.state.auctionEndDate,
                    },
                    () => {
                        this.auctionEndDateRef.current.setOpen(true);
                    }
                );
            } else {
                this.setState({
                    auctionEndDateType: value_id,
                    auctionEndDate: this.state.auctionEndDateOptions.filter((o) => {
                        return o.id === value_id;
                    })[0].text,
                });
            }
        };
        auctionEndDateChanged = (date) => {
            this.setState({
                auctionEndDate: date,
            });
        };

        validate = () => {
            let incomplete = true;
            let listingQuantityError = "";

            if (this.state.listingTypePreSelection !== "NOT_FOR_SALE") {
                incomplete = false;
            } else if (
                this.state.listingQuantity <= 0 ||
                (this.state.mintState !== "MINTED" && this.state.listingQuantity > this.state.quantityOwnedUnminted) ||
                (this.state.mintState === "MINTED" && this.state.listingQuantity > this.state.quantityOwnedMinted)
            ) {
                listingQuantityError = "Invalid quantity provided.";
            } else if (this.state.listingType === "NOT_FOR_SALE" && this.state.listingTypePreSelection !== "NOT_FOR_SALE") {
                incomplete = false;
            } else if (this.state.listingType === "FIXED_PRICE_SALE") {
                incomplete =
                    this.state.fixedPrice === null ||
                    this.state.fixedPrice <= 0 ||
                    this.state.listingQuantity <= 0 ||
                    (this.state.mintState !== "MINTED" && this.state.listingQuantity > this.state.quantityOwnedUnminted) ||
                    (this.state.mintState === "MINTED" && this.state.listingQuantity > this.state.quantityOwnedMinted) ||
                    this.state.fixedSelectedCoinSymbol === null;
            } else if (this.state.listingType === "AUCTION_SALE") {
                incomplete =
                    this.state.auctionReserve === null ||
                    this.state.auctionReserve < 0 ||
                    this.state.listingQuantity <= 0 ||
                    (this.state.mintState !== "MINTED" && this.state.listingQuantity > this.state.quantityOwnedUnminted) ||
                    (this.state.mintState === "MINTED" && this.state.listingQuantity > this.state.quantityOwnedMinted) ||
                    (this.state.auctionStartDateType === "SPECIFIC_DATE" && this.state.auctionEndDateType === "SPECIFIC_DATE" && this.state.auctionEndDate <= this.state.auctionStartDate);
            }

            this.setState({
                incomplete: incomplete,
                listingQuantityError,
            });
        };

        onClose = () => {
            navigate(`/m/${this.state.mediaId}`);
        };

        render() {
            return this.state.media === null ? (
                <Main
                    title={"BMinted"}
                    initialising={this.state.initialising}
                    auth={this.props.auth}
                    prices={this.props.prices}
                    providers={this.props.providers}
                    currentChain={this.props.currentChain}
                    chains={this.props.chains}
                >
                    <NotFoundPage notFoundName={"Media"} />
                </Main>
            ) : (
                <Main
                    title={"BMinted"}
                    initialising={this.state.initialising}
                    auth={this.props.auth}
                    prices={this.props.prices}
                    providers={this.props.providers}
                    noFooterMargin={true}
                    currentChain={this.props.currentChain}
                    chains={this.props.chains}
                >
                    {this.state.mintState !== "MINTED" && this.state.mintState !== "LAZY_MINTED" && this.state.media.mintingSetup !== true ? (
                        <ContentArea slim={true} extraTopPadding={true} bottomRule={false}>
                            <ContentArea slim={true} extraTopPadding={true} bottomRule={false}>
                                <TextArea center={true}>
                                    <p className={"caps_and_spaced"}>Media Listing</p>
                                </TextArea>
                            </ContentArea>
                            <ContentArea header={"Action Required"} slim={true} bottomRule={true}>
                                <TextArea header={"Mint Setup Needed"}>
                                    <p>
                                        Before you can list your media we need to setup minting, this will inform us of how you want to mint. You can change your mint options up until the point your
                                        media has been minted or listed for sale, at which point the settings become immutable.
                                    </p>
                                </TextArea>
                                <TextArea>
                                    <Button displayMode={5} hoverMode={6} text={"Setup Mint Options"} to={`/m/mint/setup/${this.state.mediaId}`} noMargin={true} />
                                </TextArea>
                            </ContentArea>
                        </ContentArea>
                    ) : this.state.listingTypePreSelection !== "NOT_FOR_SALE" ? (
                        <ContentArea slim={true} extraTopPadding={true} minHeight={true} bottomRule={false}>
                            <ContentArea header={"Sell Your Media."} subHeader={this.state.media.name} extraTopPadding={true} slim={true} bottomRule={false}>
                                <TextArea header={"Remove From Sale."}>
                                    <p>Your media is currently listed for sale, in order to change your listing details you'll need to un-list your media. </p>
                                    <div className={styles.flex_row}>
                                        <div className={`${styles.sale_type_container} ${styles.sale_type_container_selected}`}>
                                            <div className={styles.sale_type_logo_container}>
                                                <FontAwesomeIcon icon={faTimes} className={styles.icon} />
                                            </div>
                                            <div className={styles.sale_type_text_container}>
                                                <p className={styles.sale_type_header}>{"Remove From Sale"}</p>
                                                <p className={styles.sale_type_description}>{"Remove the media from sale."}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <LinkWithIcon icon={faArrowLeftLong} text={<span>Changed your mind? Leave it listed! &#x1F60A;</span>} onClick={this.onClose} />
                                    <br />
                                </TextArea>
                            </ContentArea>
                        </ContentArea>
                    ) : (
                        <ContentArea slim={true} extraTopPadding={true} minHeight={true} bottomRule={false} >
                            <ContentArea header={"Sell Your Media."} subHeader={this.state.media.name} extraTopPadding={true} slim={true} bottomRule={false}>
                                {/*{this.state.media.created && this.state.media.mintingSetupLocked !== true && (
                                    <TextArea header={"Minting Options."}>
                                        <p>
                                            <b>Public Address:</b>&nbsp;&nbsp;{!this.state.isMobile ? this.state.media.creator.publicAddress : Common.cropTo(this.state.media.creator.publicAddress, 15)}
                                            <br />
                                            <b>Chain Id:</b>&nbsp;&nbsp;{this.state.media.chainId}
                                            <br />
                                            <b>Royalties:</b>&nbsp;&nbsp;{`${this.state.media.royaltiesPercent}%`}
                                            <br />
                                            <b>Supply:</b>&nbsp;&nbsp;{this.state.media.totalSupply}
                                        </p>
                                        <br />
                                        <Button displayMode={5} hoverMode={6} text={"Edit Minting Options"} to={`/m/mint/setup/${this.state.media.mediaId}`} noMargin={true} />
                                        <br />
                                        <br />
                                    </TextArea>
                                )}*/}

                                <TextArea header={"Type of Sale."}>
                                    <p>How would you like to sell your media?</p>
                                    <div className={this.state.isMobile ? styles.flex_row_mobile : styles.flex_row}>
                                        {this.state.listingTypes.map((type, index) => (
                                            <div
                                                key={`listingtype_${index}`}
                                                className={`${this.state.isMobile ? styles.sale_type_container_mobile : styles.sale_type_container} ${this.state.listingType === type.id ? styles.sale_type_container_selected : ""}`}
                                                onClick={() => {
                                                    this.listingTypeSelection(type.id);
                                                }}
                                            >
                                                <div className={styles.sale_type_logo_container}>
                                                    <FontAwesomeIcon icon={type.icon} className={styles.icon} />
                                                </div>
                                                <div className={styles.sale_type_text_container}>
                                                    <p className={styles.sale_type_header}>{type.name}</p>
                                                    <p className={styles.sale_type_description}>{type.subtext}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <LinkWithIcon icon={faArrowLeftLong} text={<span>Changed your mind? Sell it later! &#x1F60A;</span>} onClick={this.onClose} />
                                    <br />
                                </TextArea>
                                {this.state.listingType !== "NOT_FOR_SALE" && (this.state.quantityOwnedMinted >= 1 || this.state.quantityOwnedUnminted >= 1) && (
                                    <TextArea header={"Quantity."}>
                                        {this.state.mintState === "MINTED" ? (
                                            <p>
                                                How many of your minted items would you like to sell? You can list up to <b>{this.state.quantityOwnedMinted}</b> items.
                                            </p>
                                        ) : (
                                            <p>
                                                How many of your un-minted items would you like to sell? You can list up to <b>{this.state.quantityOwnedUnminted}</b> items.
                                            </p>
                                        )}

                                        <Input
                                            icon={faArrowUp91}
                                            placeholder={"Quantity to list"}
                                            onTextChanged={this.onListingQuantityChanged}
                                            defaultValue={this.state.listingQuantity}
                                            regex={/^[0-9]+$/g}
                                            maxLength={10}
                                            hideMaxLength={true}
                                            speechBubbleText={this.state.listingQuantityError}
                                        />
                                        <br />
                                    </TextArea>
                                )}
                                {this.state.listingType === "FIXED_PRICE_SALE" && (
                                    <TextArea header={"Price Per NFT."}>
                                        <p>How much of which asset would you like in return for each of your listed items? This price is on a per-item basis.</p>
                                        <div className={`${styles.flex_row} ${styles.no_wrap}`}>
                                            <Input
                                                icon={faCoins}
                                                placeholder={"Sale Price"}
                                                onTextChanged={this.onFixedPriceChanged}
                                                defaultValue={this.state.fixedPrice}
                                                regex={/^[0-9]+(.)?([0-9]+)?$/g}
                                                maxLength={50}
                                                hideMaxLength={true}
                                            />
                                            <DropdownInput
                                                options={this.state.chainsFixedPrice.map((c) => {
                                                    return {
                                                        text: c.symbol,
                                                        image: `${process.env.GATSBY_STORAGE}assets/${c.icon}`,
                                                    };
                                                })}
                                                selectedText={this.state.fixedSelectedCoinSymbol}
                                                onSelectionChange={this.onFixedCoinChange}
                                            />
                                        </div>
                                        <p>
                                            <b>Service Fee:</b>&nbsp;{!!this.state.fixedSelectedCoinObj ? `${this.state.fixedSelectedCoinObj.serviceFee}%` : "-"}
                                            <br />
                                            <b>Creator Royalties:</b>&nbsp;{this.state.media.royaltiesPercent > 0 ? `${this.state.media.royaltiesPercent}%` : "-"}
                                            <br />
                                            <b>Estimated Gain (per token):</b>&nbsp;
                                            {!!this.state.fixedSelectedCoinObj && this.state.fixedPrice > 0
                                                ? `${Common.currencyFormat(
                                                      this.state.fixedSelectedCoinSymbol,
                                                      this.state.fixedPrice - this.state.fixedPrice * ((this.state.fixedSelectedCoinObj.serviceFee + this.state.media.royaltiesPercent) / 100),
                                                      3
                                                  )} (${Common.currencyFormat(
                                                      "USD",
                                                      (this.state.fixedPrice - this.state.fixedPrice * ((this.state.fixedSelectedCoinObj.serviceFee + this.state.media.royaltiesPercent) / 100)) *
                                                          Number(this.state.fixedSelectedCoinObj.usd),
                                                      2
                                                  )})`
                                                : "-"}
                                        </p>
                                    </TextArea>
                                )}
                                {this.state.listingType === "AUCTION_SALE" && (
                                    <TextArea header={"Reserve Price."} subHeader={"Hidden"}>
                                        <p>The minimum bid you will accept on your item. This value is kept hidden from bidders, only bids over the reserve price are considered for winning.</p>
                                        <div className={`${styles.flex_row} ${styles.no_wrap}`}>
                                            <Input
                                                icon={faCoins}
                                                placeholder={"Reserve Price"}
                                                onTextChanged={this.onAuctionReserveChanged}
                                                defaultValue={this.state.auctionReserve}
                                                regex={/^[0-9]+(.)?([0-9]+)?$/g}
                                                maxLength={50}
                                                hideMaxLength={true}
                                            />
                                            <DropdownInput
                                                options={this.state.chainsAuction.map((c) => {
                                                    return {
                                                        text: c.symbol,
                                                        image: `${process.env.GATSBY_STORAGE}assets/${c.icon}`,
                                                    };
                                                })}
                                                selectedText={this.state.auctionSelectedCoinSymbol}
                                                onSelectionChange={this.onAuctionReserveCoinChange}
                                            />
                                        </div>
                                    </TextArea>
                                )}
                                {this.state.listingType === "AUCTION_SALE" && (
                                    <div className={`${styles.flex_row} ${styles.no_wrap} ${styles.wrap_mb}`}>
                                        <TextArea header={"Start Date."}>
                                            <DropdownInput
                                                style={{
                                                    width: "100%",
                                                }}
                                                options={this.state.auctionStartDateOptions}
                                                selectedText={this.state.auctionStartDateType}
                                                overrideText={this.state.auctionStartDateType === "SPECIFIC_DATE" ? Common.dateString(this.state.auctionStartDate) : this.state.auctionStartDate}
                                                onSelectionChange={this.onAuctionStartDateChange}
                                                pin={'left'}
                                            />
                                        </TextArea>
                                        <TextArea header={"End Date."}>
                                            <DropdownInput
                                                style={{
                                                    width: "100%",
                                                }}
                                                options={this.state.auctionEndDateOptions}
                                                selectedText={this.state.auctionEndDateType}
                                                overrideText={this.state.auctionEndDateType === "SPECIFIC_DATE" ? Common.dateString(this.state.auctionEndDate) : this.state.auctionEndDate}
                                                onSelectionChange={this.onAuctionEndDateChange}
                                            />
                                        </TextArea>
                                    </div>
                                )}
                                <div className={styles.calendar_container}>
                                    {this.state.auctionStartDateType === "SPECIFIC_DATE" && (
                                        <DatePicker
                                            ref={this.auctionStartDateRef}
                                            selected={this.state.auctionStartDateType === "SPECIFIC_DATE" && this.state.auctionStartDate ? this.state.auctionStartDate : new Date()}
                                            onChange={this.auctionStartDateChanged}
                                            customInput={<div style={{ height: 0 }} />}
                                            showTimeInput={true}
                                            dateFormat="dd/MM/yyyy h:mm aa"
                                            minDate={new Date()}
                                            maxDate={new Date().setFullYear(new Date().getFullYear() + 1)}
                                            withPortal={true}
                                            highlightDates={this.state.auctionStartDateType === "SPECIFIC_DATE" && this.state.auctionStartDate ? this.state.auctionStartDate : new Date()}
                                            disabledKeyboardNavigation
                                        />
                                    )}
                                    {this.state.auctionEndDateType === "SPECIFIC_DATE" && (
                                        <DatePicker
                                            ref={this.auctionEndDateRef}
                                            selected={this.state.auctionEndDateType === "SPECIFIC_DATE" && this.state.auctionEndDate ? this.state.auctionEndDate : new Date()}
                                            onChange={this.auctionEndDateChanged}
                                            customInput={<div style={{ height: 0 }} />}
                                            showTimeInput={true}
                                            dateFormat="dd/MM/yyyy h:mm aa"
                                            minDate={new Date()}
                                            maxDate={new Date().setFullYear(new Date().getFullYear() + 1)}
                                            withPortal={true}
                                            highlightDates={this.state.auctionStartDateType === "SPECIFIC_DATE" && this.state.auctionStartDate ? this.state.auctionStartDate : new Date()}
                                            disabledKeyboardNavigation
                                        />
                                    )}
                                </div>
                                <br />
                                <br />
                            </ContentArea>

                            {/* <ContentArea slim={true} extraTopPadding={true} bottomRule={false}>
                                <TextArea center={true}>
                                    {this.state.media.created && this.state.media.mintingSetupLocked !== true && (
                                        <p>
                                            <b>
                                                Please note that once you list your media for sale you will not be able to change your minting options. Please check your chosen options before
                                                submitting for sale.
                                            </b>
                                            <br />
                                            <br />
                                            <br />
                                        </p>
                                    )}
                                    <div className={[styles.flex_row, styles.center]}>
                                        <Button displayMode={8} hoverMode={4} disabled={this.state.saving || this.state.incomplete} text={"Cancel"} to={`/m/${this.state.mediaId}`} />
                                        <Button
                                            displayMode={5}
                                            hoverMode={6}
                                            disabled={this.state.saving || this.state.incomplete}
                                            text={this.state.saving ? "Updating Listing..." : "Update Listing"}
                                            onClick={this.onList}
                                        />
                                    </div>
                                </TextArea>
                            </ContentArea> */}
                        </ContentArea>
                    )}

                    <MediaStickyPreview
                        media={this.state.media}
                        mediaId={this.state.mediaId}
                        image={`${process.env.GATSBY_STORAGE}media/${this.state.media.displayImage}`}
                        text={this.state.media.name}
                    />

                    <SaveBar
                        disabled={this.state.saving || this.state.incomplete}
                        text={
                            this.state.listingType === "NOT_FOR_SALE" && this.state.saving
                                ? "Removing From Sale..."
                                : this.state.listingType === "NOT_FOR_SALE"
                                ? "Remove From Sale"
                                : this.state.listingType === "FIXED_PRICE_SALE" && this.state.saving
                                ? "Listing For Sale..."
                                : this.state.listingType === "FIXED_PRICE_SALE"
                                ? "List For Sale"
                                : this.state.listingType === "AUCTION_SALE" && this.state.saving
                                ? "Listing For Auction..."
                                : this.state.listingType === "AUCTION_SALE"
                                ? "List For Auction"
                                : "Updating Listing"
                        }
                        onSave={this.onRequestListing}
                    />

                    {this.state.requiresSigning && (
                        <SignOverlay
                            type={this.state.listingTypePreSelection !== "NOT_FOR_SALE" || this.state.mintState === "MINTED" || this.state.mintState === "LAZY_MINTED" ? "AUTH" : "LAZY_MINT"}
                            media={this.state.media}
                            addresses={this.props.auth.user.addresses}
                            signBy={this.state.ownerPublicAddress}
                            onSigned={this.onSigned}
                            onMinted={this.onMinted}
                            onClose={this.hideSignOverlay}
                            currentChain={this.props.currentChain}
                            chains={this.props.chains}
                        />
                    )}
                </Main>
            );
        }
    }
);
