// extracted by mini-css-extract-plugin
export var address_chain_icon_container = "index-module--address_chain_icon_container--dqcKt";
export var address_container = "index-module--address_container--af6so";
export var address_content = "index-module--address_content--L7dlW";
export var calendar_container = "index-module--calendar_container--7Z15a";
export var chainbox_container = "index-module--chainbox_container--4TphR";
export var data_container = "index-module--data_container--RcJxE";
export var data_container_left = "index-module--data_container_left--FmblO";
export var data_container_right = "index-module--data_container_right--CrAOA";
export var data_outer_container = "index-module--data_outer_container--WUa0H";
export var data_right = "index-module--data_right--nqsoo";
export var delete_button = "index-module--delete_button--cSb6S";
export var dropdown_button = "index-module--dropdown_button--HMcPP";
export var flex_row = "index-module--flex_row--hgSav";
export var flex_row_mobile = "index-module--flex_row_mobile--wMAVS";
export var hidden_content = "index-module--hidden_content--HCbzn";
export var no_wrap = "index-module--no_wrap--Y+ORQ";
export var property_container = "index-module--property_container--Eh0W3";
export var property_list_item = "index-module--property_list_item--R5COT";
export var royaltypreselect_container = "index-module--royaltypreselect_container--97vDS";
export var sale_type_container = "index-module--sale_type_container--WAv7r";
export var sale_type_container_mobile = "index-module--sale_type_container_mobile--ib6jw";
export var sale_type_container_selected = "index-module--sale_type_container_selected--4kpL7";
export var sale_type_description = "index-module--sale_type_description--8dbdm";
export var sale_type_header = "index-module--sale_type_header--ki-QT";
export var sale_type_logo_container = "index-module--sale_type_logo_container--TKvl3";
export var sale_type_text_container = "index-module--sale_type_text_container--C+dyh";
export var wrap_mb = "index-module--wrap_mb--K70aa";