import React from "react";
import { Link } from "gatsby";
import * as styles from "./chainBox.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class ChainBox extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {}

    onClick = () => {
        if (this.props.disabled !== true) {
            this.props.onSelected && this.props.onSelected(this.props.symbol);
        }
    };

    render() {
        return (
            <div className={`${styles.container} ${this.props.selected ? styles.selected : ""} ${this.props.disabled ? styles.disabled : ""}`} onClick={this.onClick}>
                <div className={styles.image_container}>
                    <img className={styles.image} src={this.props.icon} />
                </div>
                <h4>{this.props.name}</h4>
            </div>
        );
    }
}
